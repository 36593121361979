/* Checkbox style */
input:checked ~ .dot {
  @apply transform;
  @apply translate-x-3;
  @apply bg-primary !important;
  @apply shadow;
}

input:disabled ~ .dot {
  opacity: 0.5;
}

input:focus + .switch-holder {
  @apply shadow-outlinePrimary;
}

.checkbox input:checked + div {
  @apply border-primary;
  @apply shadow;
}

.checkbox input:active + div svg {
  @apply scale-100;
}

.checkbox:hover div {
  @apply border-gray-500;
}

.checkbox-wrapper input:checked + .icon {
  @apply bg-primary !important;
  @apply border-primary !important;
}

.checkbox-wrapper input:focus + .icon {
  @apply shadow-outlinePrimary;
}

.checkbox-wrapper input:focus + .icon {
  @apply border-primary !important;
}

.checkbox-wrapper input:checked + .description {
  @apply text-primary-mainText;
}

.checkbox-wrapper input:disabled {
  cursor: default;
}

.checkbox-wrapper input:disabled + .icon {
  @apply bg-primary-tertText !important;
  @apply border-primary-tertText  !important;
}

.checkbox-wrapper input:disabled ~ .description {
  @apply text-primary-secText !important;
}

.checkbox-wrapper input:disabled:not(:checked) + .icon svg {
  display: none;
} 

.sidebar-wrapper {
  min-height: calc(100vh - 68px);
}

body .header-wrapper {
  box-shadow: 0px -2px 12px #E0E5EB !important;
}

.shadowed-content {
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
}

.table-content {
  min-height: calc(100vh - 252px);
}

/* Map Tooltip */
.map-tooltip {
  min-width: 188px;
}

.social-providers {
  max-height: 430px;
  overflow: auto;
}

.identifier-first-overlay::before {
  content: "";
  @apply bg-primary-stroke;
  position: absolute;
  left: 0;
  top: -32px;
  width: 100%;
  height: 32px;
}

/* Vic Map */
.jvectormap-tip {
  background: #ffffff !important;
  box-shadow: 0px 4px 20px rgba(5, 45, 97, 0.16);
  border-radius: 4px;
  border: 0 !important;
}

.jvectormap-tip img {
  width: 16px !important;
}

body ._u5cITtZnGk9D_6uoElx .jvectormap-zoomin,
body ._u5cITtZnGk9D_6uoElx .jvectormap-zoomout {
  background-color: #fff;
  color: #667c99;
  line-height: 14px;
  width: 16px;
  height: 16px;
  font-size: 20px;
  top: unset;
  bottom: 0;
  left: unset;
  right: 0;
  border: 1px solid #e0e5eb;
  transition: all 0.15s ease-in;
}

body ._u5cITtZnGk9D_6uoElx .jvectormap-zoomin:hover,
body ._u5cITtZnGk9D_6uoElx .jvectormap-zoomout:hover {
  background-color: #e0e5eb;
}

body ._u5cITtZnGk9D_6uoElx .jvectormap-zoomout {
  right: 30px;
}

/* Highlighted text background on languages */
.highlight-key {
  transform-style: preserve-3d;
}

.highlight-key::before {
  content: "";
  position: absolute;
  left: -8px;
  top: 0;
  width: calc(100% + 16px);
  height: 100%;
  @apply bg-transparent;
  border-radius: 4px;
  transform: translateZ(-1px);
  transition: all 0.15s ease-in;
}

.highlight-key.highlighted::before {
  @apply bg-primary-bg;
}

/* Error Page Wrapper */

.error-page-wrapper {
  padding: 20px 0;
  min-height: calc(100vh - 64px);
}

/* Input type color style */
input[type="color"] {
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  border-radius: 3px 0 0 3px;
  width: 42px;
  height: 37px;
  margin-top: -2px;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 3px 0 0 3px;
  border-right: 1px solid;
  @apply border-primary-stroke;
  padding: 0;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 3px 0 0 3px;
  @apply border border-r border-primary-stroke;
  padding: 0;
}

input[type="color"]::-moz-color-swatch {
  border: none;
  border-radius: 3px 0 0 3px;
  border-right: 1px solid;
  @apply border-primary-stroke;
  padding: 0;
}

input[type="color"]::-moz-color-swatch {
  border: none;
  border-radius: 3px 0 0 3px;
  @apply border;
  @apply border-r;
  @apply border-primary-stroke;
  padding: 0;
}

.color-input {
  top: 30px;
  left: 1px;
  z-index: 1;
}

.table-wrapper tr:last-child td {
  border-color: transparent !important;
}

.tab-moving-line {
  width: 100%;
  bottom: 0;
  height: 2px;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

textarea:not(.language-keys-textarea) {
  min-height: 100px;
}

.main-container,
.sidebar-wrapper {
  padding-top: 68px;
}

.sidebar-wrapper {
  max-height: calc(100vh - 68px);
}

/* Radio Button Style */

input[type="radio"]:checked + label {
  @apply text-primary;
}

.radio-btn-label > span {
  @apply border-gray-400;
}

.radio-btn-label:hover > span {
  @apply border-gray-600;
}

input[type="radio"]:checked + .radio-btn-label > span {
  @apply border-primary;
}

.radio-btn-label .inner-circle {
  @apply bg-white;
}

input[type="radio"]:checked + .radio-btn-label .inner-circle {
  @apply bg-primary;
}

/* Cookie categories */

.cookie-categories {
  scroll-margin-top: 80px;
}

.cookie-categories > div:not(:last-child) {
  @apply border-b border-primary-stroke;
}

/* Scrollbar style */

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: whitesmoke;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e5eb;
  border-radius: 4px;
  transition: 0.15s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9fadbf;
}

/* Arrange Apps Sidebar */

/* .arrange-apps-sidebar {
  transform: translateX(130%);
  transition: all 0.5s ease;
}

.active-sidebar .arrange-apps-sidebar  {
  transform: translate(0);
} */

.active-sidebar .applications-list {
  /* padding-right: 288px;
  transition:all 0.5s ease; */
}

body svg {
  width: inherit;
  height: inherit;
}

/* Search input */
.search-input {
  padding-right: 70px;
}

/* TODO: this is temporary, it should be deleted after the table component responsiveness is fixed */
@media (max-width: 720px) {
  .table-wrapper table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.info-section {
  position: relative;
}

.info-section::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #1d79f2;
  border-radius: 4px 0 0 4px;
}
.theme-editor {
  height: calc(100vh - 130px);
}
.accordion-content {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}

.config-sidebar::-webkit-scrollbar {
  display: none;
}

/* Track */
.config-sidebar::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.config-sidebar::-webkit-scrollbar-thumb {
  display: none;
}
.layout-item:nth-child(even) {
  margin-right: 0 !important;
}
input[type="color"].input-color {
  border-bottom-left-radius: 2px !important;
  border-top-left-radius: 2px !important;
  height: 21px;
  width: 21px;
}

.theme-dot-wrapper input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 9;
}

.theme-dot-wrapper .cc-btn {
  width: auto;
  border: 0;
  padding: 0;
}

.theme-dot-wrapper input[type="checkbox"]:disabled {
  cursor: default;
  background-color: transparent !important;
}

.theme-dot-wrapper
  > input[type="checkbox"]:disabled
  > .theme-switch-holder
  > .dot {
  pointer-events: none;
}
.theme-dot-wrapper
  > input[type="checkbox"]:checked
  + .theme-switch-holder
  > .dot {
  background-color: #1d79f2 !important;
  box-shadow: 0 2px 4px 0 rgba(29, 121, 242, 0.4);
  transform: translate(10px, -50%) !important;
}

.cmp-overlay {
  display: flex;
  visibility: visible;
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: hsla(214, 20%, 50%, 40%);
  /* // background-color: #667c99; */
  opacity: 1;
  backface-visibility: hidden;
  transition: opacity 0.1s cubic-bezier(0, 0.78, 0.03, 0.93) 0s, z-index 0s 0.1s,
    visibility 0s 0.1s;
  will-change: visibility, z-index, opacity;
}

/* Templates list lines aside */

.nested-template {
  position: relative;
}

.nested-template::before, .nested-template::after {
  content: "";
  display: inline-block;
  @apply bg-primary-stroke;
  position: absolute;
}

.nested-template::before {
  left: -24px;
  width: 23px;
  height: 1px;
}

.nested-template:first-child::after {
  height: 31px;
  top: -13px;
}

.nested-template::after {
  height: 46px;
  left: -24px;
  top: -28px; 
  width: 1px;
}

.template-icon {
  width: 44px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px 0 0 4px;
}

/* //! CONSENT TABS SWITCH */
[data-tab-content] {
  display: none;
}

.active[data-tab-content] {
  display: block;
}

.tab.active {
  color: #052d61 !important;
  font-weight: 700;
}
.tab.active::after {
  content: "";
  position: absolute;
  background: rgba(5, 45, 97, 1);
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.data-import-tab {
  min-height: 400px;
}
.horizontal-scroll.relative {
  position: static !important;
}